// import { NextIcon, PreIcon } from "../components/SVGs.tsx";
import ProductCard from "./ProductCard.tsx";
import { useState } from "preact/hooks";
import Notification from "../components/ToastNotifications/index.tsx";
import { AlertToast, SuccessToast } from "../utils/helper.ts";

function ProductsGrid({ products }: any) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [message, setMessage] = useState("");

  const closeNotification = () => {
    setShowNotification(false);
  };

  const productsPerPage = 8;
  // const totalPages = Math.ceil(products.length / productsPerPage);

  // const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct,
  );

  const addToCart = (product: any) => {
    const cartItem = {
      id: product._id,
      name: product.name,
      image: product.image,
      quantity: 1,
      price: Number(product.variants[0].price),
      category: product.category,
      variants: {
        id: product.variants[0].id,
        unit: product.variants[0].unit,
        quantity: product.variants[0].quantity,
        price: product.variants[0].price,
      },
    };
    const existingCartItems = JSON.parse(localStorage.getItem("cart-1") || "[]");
    const existingCartItem = existingCartItems.find((item: any) =>
      item.id === cartItem.id
    );
    if (existingCartItem) {
      // setNotificationType("error");
      // setShowNotification(true);
      // setMessage("Produkten finns redan i din korg!");
      AlertToast("Produkten finns redan i din korg!");
    } else {
      existingCartItems.push(cartItem);
      localStorage.setItem("cart-1", JSON.stringify(existingCartItems));
      // setNotificationType("success");
      // setShowNotification(true);
      // setMessage("Produkten har lagts till i korgen!");
      AlertToast("Produkten har lagts till i korgen!");
    }
  };

  return (
    <>
      <Notification
        showNotification={showNotification}
        closeNotification={closeNotification}
        notificationType={notificationType}
        message={message}
      />
      <div className="container lg:!pb-52 !px-0 pb-40 container-class">
        <div className="titleSection">
          <h2>
            VÅRA <span>PRODUKTER</span>
          </h2>
          <div className="divider"></div>
        </div>
        <div className="row row-mob">
          {currentProducts.map((product: any) => {
            return <ProductCard product={product} addToCart={addToCart} />;
          })}
        </div>
        <div className="pagination flex justify-end mt-8 gap-2">
          {
            /* <button
            className={`flex items-center mx-1 px-3 py-2 bg-gray-200 text-gray-500 font-medium rounded-md ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <PreIcon />
            Previous
          </button> */
          }
          {
            /* {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`pagination-button py-1 px-3 rounded-sm ${
                currentPage === index + 1
                  ? "current-page bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))} */
          }
          {
            /* <button
            className={`d-flex items-center mx-1 px-3 py-2 bg-gray-200 text-gray-500 font-medium hover:bg-blue-500 hover:text-gray-200 rounded-md ${
              currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
            <NextIcon />
          </button> */
          }
        </div>
      </div>
    </>
  );
}

export default ProductsGrid;

